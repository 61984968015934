<template>
  <div id="audits-container">

    <h2><i class="fad fa-fw" :class="activeRoute.icon"></i> {{ activeRoute.title }}</h2>
    <p class="lead">
      {{ activeRoute.description }}
    </p>

    <router-link to="/audits" class="btn btn-secondary mb-5 mr-3">Zurück zur Übersicht</router-link>
    <button @click="save" class="btn btn-primary mb-5">Audit speichern</button>

    <!--<p>Todo:</p>
    <ul>
      <li>Make available a selection of sites to audit based on Customer Journey</li>
      <li>Build visual copy of those pages and be able to add notes in place</li>
      <li>Make background connection to Levels and Limbics</li>
      <li>Connect to <router-link to="/links">Link Tree</router-link></li>
      <li>Connect to <router-link to="/journeys">Customer Journeys</router-link></li>
      <li>Connect to <router-link to="/findings">Findings</router-link></li>
      <li>Connect to <router-link to="/ideas">Idea Backlog</router-link></li>
    </ul>-->

    <div class="row justify-content-center mb-4">
      <div v-if="step >= 0" class="col-sm-11 col-md-7">
        <EditProgress :steps="editSteps" :step-index="step" @setStep="step = $event" :skippable="true"></EditProgress>
      </div>
    </div>

    <div v-if="step === 0" class="card">
      <div class="card-header">
        <h3>Dein neuer Audit</h3>
      </div>
      <div class="card-body">
        <p>
          Hier kannst du eine Analyse deiner Seiten durchführen. <br>
          Du kannst dazu entweder deine bereits erstellten Customer Journeys durchlaufen, oder einzelne Seiten zum
          Analysieren auswählen.
        </p>

        <div class="form-group">
          <h4 class="mt-4">Wie soll dieser Audit heißen?</h4>
          <p>Wir führen dich dann schrittweise durch die Erstellung.</p>
          <label>Name des Audits:</label>
          <input type="text" v-model="newAudit.title" class="form-control">
        </div>

        <div class="form-group">
          <h4>Willst du deinen Audit direkt an eine Theorie knüpfen?</h4>
          <label>Zu welcher Theorie soll dieser Audit gehören?</label>
          <select v-model="newAudit.theoryId" class="form-control mb-3">
            <option value="">Keine Theorie</option>
            <option v-for="(theory, index) in project.theories"
                    :value="theory.id"
                    :selected="theory.id === activeTheoryId"
                    :key="index"
            >
              {{ theory.title }}
            </option>
          </select>
        </div>

        <div class="form-group">
          <h4>Welche Seiten willst du auditieren?</h4>

          <p>
            Du möchtest mehrere Seiten auf einmal auditieren? <br>
            Nutze einfach <router-link to="/journeys">deine Customer Journeys</router-link> oder erstelle
            <router-link to="/journeys/add">hier</router-link> eine neue.
          </p>

          <div @click="connectJourney = !connectJourney" class="btn-group btn-block mb-3">
            <button class="btn" :class="connectJourney ? 'btn-primary' : 'btn-secondary'">Journey verknüpfen</button>
            <button class="btn" :class="!connectJourney ? 'btn-primary' : 'btn-secondary'">Seiten auswählen</button>
          </div>

          <div class="border rounded p-3">
            <div v-if="connectJourney">
              <div v-for="(journey, index) in journeys"
                   class="card mb-3"
                   :class="newAudit.journeyId === journey.id ? 'border-primary' : ''"
                   :key="index"
                   @click="newAudit.journeyId = journey.id"
              >
                <div class="card-header">
                  <h5 class="mb-0">{{ journey.title }}</h5>
                </div>
                <div class="card-body">
                  <NodeSelector :exclude-items="['all']"
                                :nodes="journey.steps.map(s => findNode(s.nodeUrl))"
                                node-size="col-sm-4 col-md-3 col-lg-2"
                                @selectNode="selectNode($event)"
                  ></NodeSelector>
                </div>
              </div>
            </div>
            <div v-else>
            <div class="row">
              <div class="col-sm-6">
                <h5>Deine Seiten:</h5>
                <input v-model="search" class="form-control mb-3" placeholder="Seite suchen..." type="text">
                <NodeSelector :exclude-items="['all']"
                              :nodes="newNodes"
                              node-size="col-lg-6"
                              wrapperClasses="scroll-container-v"
                              @selectNode="selectNode($event)"
                ></NodeSelector>
              </div>
              <div class="col-sm-6">
                <div class="sticky-top">
                  <h5 class="mb-5">Deine ausgewählten Seiten:</h5>
                  <NodeSelector :exclude-items="['all']"
                                :nodes="selectedNodes"
                                node-size="col-lg-6"
                                @selectNode="unselectNode($event)"
                  ></NodeSelector>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="row">
          <div class="col-sm-6">
            <button @click="save('/audits')" class="btn btn-block btn-secondary">Audit nur erstellen</button>
          </div>
          <div class="col-sm-6">
            <button @click="save('/audits/edit/' + newAudit.id)" class="btn btn-block btn-primary">Audit erstellen und bearbeiten</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import EditProgress from "@/components/EditProgress";
import NodeSelector from "@/components/NodeSelector";

export default {
  name: 'AuditsEdit',
  components: {
    NodeSelector,
    EditProgress
  },
  data() {
    return {
      step: 0,
      editSteps: [
        "Journey / Seite auswählen",
        "Audit durchführen"
      ],
      connectJourney: true,
      search: "",
      selectedNodes: [],
      newAudit: {
        id: "",
        theoryId: this.activeTheoryId,
        roadmapItemId: "",
        journeyId: "",
        title: "",
        summary: "",
        timestamp: +Date.now(),
        device: [],
        steps: []
      },
      newStep: {
        id: this.generateId(),
        nodeUrl: "",
        summary: "",
        image: "",
      },
    }
  },
  computed: {
    journeys() {
      return this.project.journeys;
    },
    nodes() {
      let map = [];
      map = map.concat(JSON.parse(JSON.stringify(this.project.netNodes)));
      map = map.concat(JSON.parse(JSON.stringify(this.project.externalNodes)));
      return map;
    },
    newNodes() {
      let searchNode = node => {
        let isSearched = this.search === "";
        if (!isSearched) {
          for (let prop of ['url', 'title']) {
            if (node[prop].indexOf(this.search) >= 0) {
              isSearched = true;
            }
          }
        }
        return isSearched;
      }
      return [{
        id: this.generateId(),
        url: "Platzhalter",
        title: "Leerer Schritt (füge hier eigene Seiten hinzu)",
        image: {
          thumb: "",
          desktop: "",
          tablet: "",
          mobile: ""
        }
      }]
          .concat(this.project.netNodes.filter(searchNode))
          .concat(this.project.externalNodes.filter(searchNode));
    },
    journeyNodes() {
      let nodes;
      if (this.newAudit.journeyId) {
        let journey = this.journeys.find(j => j.id === this.newAudit.journeyId);
        nodes = journey.steps.map(s => this.findNode(s.nodeUrl));
      }
      return nodes;
    }
  },
  methods: {
    selectNode($event) {
      this.selectedNodes.push($event);
    },
    unselectNode($event) {
      let index = this.selectedNodes.findIndex(n => n.url === $event.url);
      this.selectedNodes.splice(index, 1);
    },
    save(url) {
      if (this.connectJourney) {
        let journey = JSON.parse(JSON.stringify(this.journeys.find(j => j.id === this.newAudit.journeyId)));
        this.newAudit.steps = journey.steps.map(step => {
          let temp = JSON.parse(JSON.stringify(this.newStep));
          temp.nodeUrl = step.nodeUrl;
          temp.id = this.generateId();
          return temp;
        });
      } else {
        this.newAudit.journeyId = "";
        this.newAudit.steps = this.selectedNodes.map(node => {
          let temp = JSON.parse(JSON.stringify(this.newStep));
          temp.nodeUrl = node.url;
          temp.id = this.generateId();
          return temp;
        })
      }
      this.project.audits.push(this.newAudit);
      this.$store.dispatch('project/updateProjectByProp', {prop: 'audits', data: this.project.audits})
          .then(res => {
            console.debug(res);
            this.$router.push(url);
          });
    },
  },
  beforeMount() {
    this.newAudit.id = this.generateId();
  }
}
</script>

<style lang="scss">
.drag-container {
  width: 250px;

  .drag-container-body {
    height: 100%;
    padding: 1rem;
    overflow-y: scroll;
    z-index: 9;
  }
}
.scroll-container-v {
  max-height: 80vh;
  height: 30rem;
}
.order-indicator {
  position: absolute;
  top: -1rem;
  right: -1rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: var(--dark);
  text-align: center;
  font-size: 1rem;
  padding: .25rem;

  &, a { color: var(--white); }
}
</style>